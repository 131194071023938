/**
 * 1024新官网产品购买组件
 * 传入 pro_intro 对象
 * const pro_intro={
    img:'',
    name:"XREAL Beam",
    cate:"beam",
    shop_link:"",
    amazon_link:"",
    amazon_sale:<></>,
}
 * 参数包括： img:'',     //产品图链接
            mobileImg:"", //产品图移动端链接
            name:"",  //产品名
            cate:"", //产品种类 便于埋点
            shop_link:"", //自家商城链接 （无则不显示
            amazon_link:"", //amazon商城链接 （无则不显示
            amazon_sale:<></>, //amazon是否打折 打折的话传入打折logo
 */

import React ,{useEffect}from "react";
import style from './index.module.less'
import { XButton } from '@/components/index'
import useGa from '@/hooks/useGa'
import { useIntl } from "react-intl"
import useBigScreen from "@/hooks/bigScreen"
// import SaleLogo from '@/images/icon/saleLogo.svg'
import {SaleLogo} from '@/components/index'
import { buy_link } from '@/scripts/constant.js'
import getCountry from '@/utils/getCountry.js'
import { useInView } from "react-intersection-observer"

const ProBuy = ({ pro_intro }) => {
    const region = getCountry()
    const [btnRef , btnRefInview] = useInView({ threshold: 0 });
    const ifPc = useBigScreen(768)
    const intl = useIntl()
    const {
        img, name, cate, shop_link, amazon_link, amazon_sale, mobileImg,sale_shop
    } = pro_intro
    useEffect(()=>{
        if(!!btnRefInview){
            typeof sensors !== "undefined" &&  sensors.track('module_view', {
                custom: {
                    module_id: 10012,
                    products_name: cate.toLowerCase()
                }
            });
        }
    },[btnRefInview])
    const onBuyClick = () => {
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 10012,
                products_name:cate.toLowerCase()
            }
        });
        useGa(cate, `${cate.toLowerCase()}_below_buy`, "Click");
        window.open(shop_link)
    }
    const onAmazonClick = () => {
        typeof sensors !== "undefined" &&  sensors.track('module_interaction', {
            custom: {
                module_id: 10013,
                products_name:cate.toLowerCase()
            }
        });
        useGa(cate, `${cate.toLowerCase()}_below_buy_amazon`, "Click");
        window.open(amazon_link)
    }
    return (
        <div className={style.buy} id='pro-buy'>
            <div className={style.proBuy}>
                <div className={style.proBuyImg}
                    style={{ backgroundImage: `url(${ifPc ? img : mobileImg})` }}
                ></div>
                <div className={style.proBuyIntro}>
                    <div className={style.proBuyIntroName}>{name}</div>
                    <div className={style.proBuyIntroBtns}>
                        {
                            !!shop_link &&
                            <XButton width="200px" styles={{ margin: '10px' }}
                                onClick={onBuyClick} >
                                    {sale_shop&& <SaleLogo text={buy_link[region]['air'].sale_shop_reduce}/>}
                                    <div ref={btnRef}>{intl.formatMessage({ id: "buy_now", defaultMessage: "Buy Now" })}</div>
                                    </XButton>
                        }
                        {
                            !!amazon_link &&
                            <XButton width="200px" styles={{ margin: '10px' }}
                                type={!!shop_link && 'secondary'}
                                onClick={onAmazonClick}>{intl.formatMessage({ id: "buy_amazon", defaultMessage: "Buy on Amazon" })}</XButton>
                        }

                    </div>
                </div>
            </div>
        </div>

    )
}

export default ProBuy
