let SupportPic = `${process.env.resourceUrl}images/support/supportBg.webp`
let AirPro = `${process.env.resourceUrl}images/support/airPro.jpg`
let LightPro = `${process.env.resourceUrl}images/support/lightPro.jpg`
let BeamPro = `${process.env.resourceUrl}images/support/beamPro.jpg`
let AdaPro = `${process.env.resourceUrl}images/support/adaPro.jpg`

const nebulaLogo = `${process.env.resourceUrl}images/support/pro-nebula.webp`
const airLogo = `${process.env.resourceUrl}images/support/pro-air.webp`
const lightLogo = `${process.env.resourceUrl}images/support/pro-light.webp`
const adaLogo = `${process.env.resourceUrl}images/support/pro-ada.webp`
const beamLogo = `${process.env.resourceUrl}images/support/pro-beam.webp`
const playImg1 = `${process.env.resourceUrl}images/support/playImg1.webp`
const playImg2 = `${process.env.resourceUrl}images/support/playImg2.webp`
const playImg3 = `${process.env.resourceUrl}images/support/playImg3.webp`
const playImg4 = `${process.env.resourceUrl}images/support/playImg4.webp`
const playImg5 = `${process.env.resourceUrl}images/support/playImg5.webp`
const playImg6 = `${process.env.resourceUrl}images/support/playImg6.webp`
const playImg7 = `${process.env.resourceUrl}images/support/playImg7.webp`
const playImg8 = `${process.env.resourceUrl}images/support/playImg8.webp`
const supportAirImg1 = `${process.env.resourceUrl}images/support/supportAirImg1.jpg`
const supportAirImg3 = `${process.env.resourceUrl}images/support/supportAirImg3.jpg`


import update from './updateLogo.svg'
import manuals from './manuals.svg'
import contactLogo from './contact.svg'
import forumLogo from './forum.svg'
import discordLogo from './discord.svg'
import emailLogo from './email.svg'
import emailBlackLogo from './emailBlack.svg'
import playBtn from './playBtn.svg'
import faqArrow from './faqArrow.png'
import discordIcon from './discord.svg'
const play1 = `${process.env.resourceUrl}video/support/playForAndroid.mp4`
const play2 = `${process.env.resourceUrl}video/support/playForiPhones.mp4`
const play3 = `${process.env.resourceUrl}video/support/playForMacbook.mp4`
const play4 = `${process.env.resourceUrl}video/support/playForSteam.mp4`
const play5 = `${process.env.resourceUrl}video/support/forForPlayStation.mp4`
const play6 = `${process.env.resourceUrl}video/support/forForXbox.mp4`
const play7 = `${process.env.resourceUrl}video/support/playForSwitch.mp4`
const play8 = `${process.env.resourceUrl}video/support/playUseAir2.mp4`
const supportAirVideo1 = `${process.env.resourceUrl}video/support/airOpenBox.mp4`
const supportAirVideo3 = `${process.env.resourceUrl}video/support/airCompatibility.mp4`
const supportBeamVideo1 = `${process.env.resourceUrl}video/support/beamStart.mp4`
const supportBeamVideo1_jp = `${process.env.resourceUrl}video/support/beamStart_jp.mp4`
const supportBeamVideoPic = `${process.env.resourceUrl}images/support/supportBeam.png`
const supportFaq1 = `${process.env.resourceUrl}images/support/supportFaq1.webp`
const supportFaq2 = `${process.env.resourceUrl}images/support/supportFaq2.webp`
const supportFaq3 = `${process.env.resourceUrl}images/support/order-help.webp`
const developerBg = `${process.env.resourceUrl}images/support/developerBg.webp`


const supportProBg = `${process.env.resourceUrl}images/support/supportProBg.webp`
const supportAirPro = `${process.env.resourceUrl}images/support/supportAirPro.webp`
const supportLightPro = `${process.env.resourceUrl}images/support/supportLightPro.webp`
const supportBeamPro = `${process.env.resourceUrl}images/support/supportBeamPro.webp`
const supportAdaPro = `${process.env.resourceUrl}images/support/supportAdaPro.webp`
const supportAir2 = `${process.env.resourceUrl}images/support/supportAir2.webp`
const proAir2 = `${process.env.resourceUrl}images/support/pro-air2.webp`
const proAir2Ultra = `${process.env.resourceUrl}images/support/pro-air2ultra.webp`
const supportAir2Ultra = `${process.env.resourceUrl}images/support/supportAir2Ultra.webp`
const UserManual_desktop = `${process.env.resourceUrl}images/support/UserManual_desktop.webp`
const UserManual_mobile = `${process.env.resourceUrl}images/support/UserManual_mobile.webp`

export {
    UserManual_desktop,
    UserManual_mobile,
    supportAir2Ultra,
    proAir2Ultra,
    proAir2,
    supportAir2,
    developerBg,
    supportFaq1,
    supportFaq2,
    supportFaq3,
    emailBlackLogo,
    discordLogo,
    supportAirVideo1,
    supportAirVideo3,
    nebulaLogo,
    airLogo,
    lightLogo,
    adaLogo,
    beamLogo,
    discordIcon,
    faqArrow,
    SupportPic,
    update,
    manuals,
    contactLogo,
    emailLogo,
    forumLogo,
    playBtn,
    AirPro,
    LightPro,
    BeamPro,
    AdaPro,
    play1,
    play2,
    play3,
    play4,
    play5,
    play6,
    play7,
    play8,
    playImg1,
    playImg2,
    playImg3,
    playImg4,
    playImg5,
    playImg6,
    playImg7,
    playImg8,
    supportAirImg1,
    supportAirImg3,
    supportProBg,
    supportAirPro,
    supportLightPro,
    supportBeamPro,
    supportAdaPro,
    supportBeamVideo1,
    supportBeamVideoPic,
    supportBeamVideo1_jp
}